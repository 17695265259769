<template>
  <ion-page>

    <ion-header class="ion-no-border">
      <ion-toolbar color="secondary">
        <ion-buttons slot="start">
          <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
        </ion-buttons>

        <ion-icon class="app-logo" slot="start" size="large" src="/assets/icon/giraffe1.svg"></ion-icon>
        <ion-title color="primary">{{ props.pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content id="pageContent" :fullscreen="true">
      <slot/>
      <div class="bottomPadding" :style="bottomPaddingStyle"></div>
    </ion-content>

    <footer>
      <slot name="footer"></slot>
    </footer>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonIcon
} from "@ionic/vue";

// import { ear } from "ionicons/icons";

const props = defineProps(["pageTitle", "pageDefaultBackLink"]);

const isInIos = navigator.userAgent.includes('iPhone');

// for solving iphone keyboard covering input box issue - JW 2025-02-08 */
//const bottomPaddingStyle = {height: isInIos ? '6.5em' : '5.5em'};
const bottomPaddingStyle = {height: isInIos ? '18.5em' : '5.5em'};  // 18.5em is for iphone 16 Pro Max

</script>

<style>
.ion-page {
  margin: 0 auto;
  max-width: 500px;

}

.app-logo {
  margin-left: 12px;
}

ion-header {
}

ion-content {
  /* --ion-background-color:#e7f5fe; */
  --background: url('@/img/background.jpg') no-repeat center center / cover;
}


/* ion-toolbar{
  --background: #9ad0c2 !important;
} */

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.navBtn {
  /*--box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);*/
  /* --background: #215C52; */
  /* color: white; */
  font-size: 16px;
  font-weight: bold;
}

.transparent {
  --background: transparent;
}

</style>



